@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-purple: #4C4DA8;
    --color-blue: #1E87CE;
  }
}

body{
	@apply p-safe text-gray-900 antialiased bg-blue-light;

	/* PWA enhancements */
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	/* background: #444; */

}

html{
	height: 100vh;
  -webkit-overflow-scrolling: touch;
}

#modal {
	z-index: 9999;
}

input {
	font-size: 16px !important;
}

.active-border {
	position: relative;
}

.active-border::before {
	content: "";
	position: absolute;
	bottom: -3px;
	left: -2px;
	width: 45%;
	height: 3px;
	border-radius: 5px;
	background: #FDB533;
}
